import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './StyleCadastro.css';

function Voltar({ ative, isButtonDisabled, show }) {
  const handleVoltar = () => {
    const setAtive = ative[1];
    const setShow = show[1];
    const setIsButtonDisabled = isButtonDisabled[1];
    setAtive(ative[0] - 1);
    setIsButtonDisabled(false);
    setShow(true);
  };

  return (
    <footer className="step-footer">
      <button type="button" className="btn btn-outline-primary voltar" onClick={handleVoltar}>VOLTAR&nbsp;&nbsp;</button>
    </footer>
  );
}

export default Voltar;

Voltar.propTypes = {
  ative: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.func])).isRequired,
  isButtonDisabled:
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])).isRequired,
  show: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])).isRequired,
};

// style={{
//   width: '100%',
//   marginTop: '18px',
//   textTransform: 'uppercase',
// }}
// width: 191px;
