/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './StyleCadastro.css';
import { mask as masker, unMask } from 'remask';
import Avancar2 from './Avancar2';
import Voltar from './Voltar';
import Logo from './iglogo.png';

function Step2({
  ative,
  cep,
  cidade,
  estado,
  endereco,
  bairro,
  numero,
  complemento,
  isButtonDisabled,
  show,
  showNotKnowCEP,
}) {
  const cepState = cep[0];
  const [valueCep, setValueCep] = useState(cepState);
  const [showInvalidCEP, setShowInvalidCEP] = useState(false);
  const [invalidCEP, setInvalidCEP] = useState({});
  const [showInvalidNumber, setShowInvalidNumber] = useState(false);

  const showNotKnow = showNotKnowCEP[0];
  const setShowNotKnow = showNotKnowCEP[1];

  const setCep = cep[1];
  const setCidade = cidade[1];
  const setEstado = estado[1];
  const setEndereco = endereco[1];
  const setBairro = bairro[1];
  const setNumero = numero[1];
  const setComplemento = complemento[1];
  const setShow = show[1];

  const showState = show[0];
  const numeroState = numero[0];

  const setIsButtonDisabled = isButtonDisabled[1];

  const isInputValid = () => {
    const numberToComperCep = 9;
    if (cepState.length !== numberToComperCep) {
      setIsButtonDisabled(true);
    }
    if (numeroState === '') {
      setIsButtonDisabled(true);
      setShowInvalidNumber(true);
    }
    if (numeroState !== '') {
      setShowInvalidNumber(false);
    }
    if (numeroState !== '' && cepState.length === numberToComperCep) {
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => { isInputValid(); }, [cepState, numeroState, showInvalidNumber]);

  const { register, setValue } = useForm();

  // função que faz o fetch para a AI de ceps e seta os valores dos inputs
  const checkCEP = (e) => {
    const getCEP = e.target.value.replace(/\D/g, '');
    fetch(`https://viacep.com.br/ws/${getCEP}/json/`)
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          setShowNotKnow(true);
          setShow(false);
          setShowInvalidCEP(true);
          setInvalidCEP({
            margin: '0.9rem 4px',
            display: 'flex',
            flexDirection: 'column',
          });
          e.target.classList.add('wrong');
          e.target.classList.remove('correct');
        } else {
          setShowNotKnow(false);
          setValue('cidade', data.localidade);
          setCidade(data.localidade);
          setValue('estado', data.uf);
          setEstado(data.uf);
          setValue('endereco', data.logradouro);
          setEndereco(data.logradouro);
          setValue('bairro', data.bairro);
          setBairro(data.bairro);
          setShow(true);
          setShowInvalidCEP(false);
          setInvalidCEP({
            margin: '0 auto',
          });
          e.target.classList.remove('wrong');
          e.target.classList.add('correct');
        }
      });
  };

  const getMask = (ev, model, setState, setValueInput) => {
    const originalValue = unMask(ev.target.value);
    const maskValue = masker(originalValue, [model]);
    setState(maskValue);
    setValueInput(maskValue);
  };

  return (
    <div className="body">
      <div className="header-ig1">
        <img className="logo-ig" src={Logo} alt="" />
      </div>
      <header>
        <section className="header-section">
          <h1 className="ig-title">Todos os serviços iG, uma única conta!</h1>
          <h1 className="ig-instructions">Informações Residencial</h1>
        </section>
      </header>
      <form className="needs-validation2" noValidate>
        <div className="form-row" id="second">
          <section className="steps steps2">
            <p className="step-atual" id="indice1">1</p>
            <div className="blue-line" />
            <p className="step-atual" id="indice2">2</p>
            <div className="blue-line-2" />
            <p className="step-third-grey" id="indice3">3</p>
          </section>
          <div className="cep mb-1" style={invalidCEP}>
            <FloatingLabel htmlFor="cep" id="cepLabel" label="Digite seu CEP" className="mb-3">
              <Form.Control
                type="tel"
                className="form-control"
                id="cep"
                value={valueCep}
                name={cep}
                onChange={(event) => {
                  getMask(event, '99999-999', setCep, setValueCep);
                }}
                onKeyUp={(event) => {
                  if (event.target.value.length === 9) {
                    checkCEP(event);
                  }
                }}
                placeholder="Digite seu CEP"
                maxLength="9"
                minLength="9"
                required
              />
            </FloatingLabel>
            <div
              className="invalid-feedback-cep"
              style={{
                display: showInvalidCEP ? 'block' : 'none',
                color: 'red',
                height: '20px',
                margin: '0 auto',
                fontSize: '12px',
              }}
            >
              Por favor, insira um CEP válido.
            </div>
            { showNotKnow && (
            <a
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              rel="noreferrer"
              style={{
                margin: '10px auto 0',
              }}
            >
              Não sei meu CEP
            </a>
            )}
          </div>
          <div id="address-div" style={{ display: showState ? 'block' : 'none' }}>
            <div className="row">
              <div className="col-md-6 mb-1 endereco">
                <FloatingLabel htmlFor="endereco" label="Endereço" className="mb-3">
                  <Form.Control
                    type="string"
                    className="form-control"
                    id="endereco"
                    value={endereco[0]}
                    name={endereco}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('endereco')}
                    placeholder="Endereço"
                    required
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-6 mb-1 bairro">
                <FloatingLabel htmlFor="bairro" label="Bairro" className="mb-3">
                  <Form.Control
                    type="string"
                    className="form-control"
                    id="district"
                    value={bairro[0]}
                    name={bairro}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('bairro')}
                    placeholder="Bairro"
                    required
                  />
                </FloatingLabel>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-1 cidade">
                <FloatingLabel htmlFor="cidade" label="Cidade" className="mb-3">
                  <Form.Control
                    type="string"
                    className="form-control"
                    value={cidade[0]}
                    id="cidade"
                    name={cidade}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('cidade')}
                    placeholder="Cidade"
                    required
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-6 mb-1 estado">
                <FloatingLabel htmlFor="estado" label="Estado" className="mb-3">
                  <Form.Control
                    type="string"
                    className="form-control"
                    id="estado"
                    value={estado[0]}
                    name={estado}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('estado')}
                    placeholder="Estado"
                    required
                  />
                </FloatingLabel>
                <div className="invalid-feedback">
                  Por favor, insira um estado válido.
                </div>
              </div>
            </div>
            <div
              className="row"
            >
              <div className="col-md-6 mb-1 numero">
                <FloatingLabel htmlFor="numero" label="Número" className="mb-3">
                  <Form.Control
                    type="number"
                    className="form-control"
                    id="numero"
                    value={numero[0]}
                    name={numero}
                    onChange={(event) => {
                      setNumero(event.target.value);
                      isInputValid(event);
                    }}
                    placeholder="Número"
                    maxLength="6"
                    minLength="2"
                    required
                  />
                </FloatingLabel>
                <div
                  className="invalid-feedback"
                  style={{
                    display: showInvalidNumber ? 'block' : 'none',
                    color: 'red',
                    fontSize: '.875em',
                    marginTop: '-12px',
                    width: '200px',
                  }}
                >
                  O campo número é obrigatório.
                </div>
              </div>
              <div className="col-md-6 mb-1 complemento">
                <FloatingLabel htmlFor="complemento" label="Complemento" className="mb-3">
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="complemento"
                    value={complemento[0]}
                    name={complemento}
                    onChange={(event) => {
                      setComplemento(event.target.value);
                    }}
                    placeholder="Complemento"
                    required
                  />
                </FloatingLabel>
                <div className="invalid-feedback">
                  Por favor, insira um complemento válido.
                </div>
              </div>
            </div>
            <div className="btns">
              <Voltar ative={ative} isButtonDisabled={isButtonDisabled} show={show} />
              <Avancar2 ative={ative} isButtonDisabled={isButtonDisabled} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Step2;

Step2.propTypes = {
  ative: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.func])).isRequired,
  cep: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  cidade: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  estado: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  endereco: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  bairro: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  numero: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  complemento:
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  isButtonDisabled:
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])).isRequired,
  show: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])).isRequired,
  showNotKnowCEP:
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])).isRequired,
};
