import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function Tags({
  ogTitle,
  ogDescription,
  ogUrl,
  ogSiteName,
  description,
  keywords,
}) {
  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:image" content="https://i0.statig.com.br/favicon/favicon.ico" />
      <meta property="og:site_name" content={ogSiteName} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default Tags;

Tags.propTypes = {
  ogTitle: PropTypes.string.isRequired,
  ogDescription: PropTypes.string.isRequired,
  ogUrl: PropTypes.string.isRequired,
  ogSiteName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
};
