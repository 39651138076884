/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './StyleCadastro.css';
import { mask as masker, unMask } from 'remask';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Voltar from './Voltar';
import Logo from './iglogo.png';

function Step3({
  ative,
  celular,
  cpf,
  genero,
  nascimento,
  senha,
  confirma,
  handleSubmit,
  isButtonDisabled,
  show,
}) {
  const [valueCel, setValueCel] = useState(celular[0]);
  const [valueCpf, setValueCpf] = useState(cpf[0]);

  const [showInvalidNasc, setShowInvalidNasc] = useState(true);
  const [nascMessage, setNascMessage] = useState('');

  const [showInvalidSenha, setShowInvalidSenha] = useState(false);
  const [senhaMessage, setSenhaMessage] = useState('');

  const [showInvalidConfirma, setShowInvalidConfirma] = useState(false);
  const [confirmaMessage, setConfirmaMessage] = useState('');

  const [showInvalidCel, setShowInvalidCel] = useState(false);
  const [celMessage, setCelMessage] = useState('');

  const [invalidCpfMessage, setInvalidCpfMessage] = useState('');
  const [invalidCpfMessage2, setInvalidCpfMessage2] = useState('');

  const setIsButtonDisabled = isButtonDisabled[1];

  const setCelular = celular[1];
  const setCpf = cpf[1];
  const setGenero = genero[1];
  const setNascimento = nascimento[1];
  const setSenha = senha[1];
  const setConfirma = confirma[1];

  const celularState = celular[0];
  // const cpfState = cpf[0];
  const nascimentoState = nascimento[0];
  const generoState = genero[0];
  const senhaState = senha[0];
  const confirmaState = confirma[0];

  const numberToComperCel = 15;
  const celNotLongEnough = celularState.length < numberToComperCel;
  const cpfRegex = /^(\d)\1\1(\.\1{3}){2}-\1\1$/;

  const validCellInput = (event) => {
    if (celNotLongEnough) {
      setShowInvalidCel(true);
      setIsButtonDisabled(true);
      setCelMessage(
        <span>
          O celular está incompleto.
        </span>,
      );
      event.target.classList.add('wrong');
    }

    if (!celNotLongEnough) {
      setShowInvalidCel(false);
      event.target.classList.remove('wrong');
      event.target.classList.add('correct');
    }
  };

  function isValidCPF(cpf2) {
    if (cpf2.length !== 14 || cpfRegex.test(cpf2)) {
      return false;
    }

    const digtsOnly = cpf2.replace(/[.-]/g, '');
    const [firstDigit, secondDigit] = digtsOnly.slice(-2);

    const firstRemaining = [...digtsOnly.slice(0, 9)]
      .reduce((a, b, i) => a + ((10 - i) * b), 0) * 10 % 11 % 10;
    const secondRemaining = [...digtsOnly.slice(0, 10)]
      .reduce((a, b, i) => a + ((11 - i) * b), 0) * 10 % 11 % 10;

    return firstRemaining === Number(firstDigit) && secondRemaining === Number(secondDigit);
  }

  const validCpflInput = (event) => {
    const getValidCpf = isValidCPF(valueCpf);
    if (getValidCpf) {
      setInvalidCpfMessage2(
        'CPF é válido',
      );
      setInvalidCpfMessage(
        '',
      );
      event.target.classList.remove('wrong');
      event.target.classList.add('correct');
    } else {
      setIsButtonDisabled(true);
      setInvalidCpfMessage(
        'CPF é inválido',
      );
      event.target.classList.remove('correct');
      event.target.classList.add('wrong');
      setInvalidCpfMessage2(
        '',
      );
      event.target.classList.add('wrong');
    }
  };

  const hoje = new Date();
  const ano = hoje.getFullYear();
  const nascStr = `'${nascimentoState}'`;
  const nascStrAux = nascStr.replaceAll("'", '');
  const nascStrSplits = nascStrAux.split('/', 3);

  const notUserAge = ano - Number(nascStrSplits[2]) < 16 || Number(nascStrSplits[2]) < 1900;
  const notValueDate = nascimentoState.length === 0;

  const validNasclInput = (event) => {
    if (notUserAge) {
      setShowInvalidNasc(true);
      setIsButtonDisabled(true);
      setNascMessage(
        <span>
          Você precisa ser maior de 16 anos.
        </span>,
      );
      event.target.classList.remove('correct');
      event.target.classList.add('wrong');
    }

    if (notValueDate) {
      setShowInvalidNasc(true);
      setIsButtonDisabled(true);
      setNascMessage(
        <span>
          Este campo não pode ser vazio.
        </span>,
      );
      event.target.classList.remove('correct');
      event.target.classList.add('wrong');
    }

    if (!notUserAge && !notValueDate) {
      setShowInvalidNasc(false);
      event.target.classList.remove('wrong');
      event.target.classList.add('correct');
    }
  };

  const re = /^(?=.*[A-Z])(?=.*[!#@$%&])(?=.*[0-9])(?=.*[a-z]).{6,15}$/;

  const invalidPassword = !re.test(senhaState) || senhaState.length < 8;
  const validPassword = re.test(senhaState) && senhaState.length >= 8;

  const validSenhaInput = (event) => {
    if (invalidPassword) {
      setShowInvalidSenha(true);
      setIsButtonDisabled(true);
      setSenhaMessage(
        <span>
          No mínimo 8 dígitos, números,
          letras maiúsculas e caracteres
          especiais.
        </span>,
      );
      event.target.classList.add('wrong');
    }

    if (validPassword) {
      setShowInvalidSenha(false);
      event.target.classList.remove('wrong');
      event.target.classList.add('correct');
    }
  };

  const notConfirmedPass = senhaState !== confirmaState;

  const validConfirmPassInput = (event) => {
    if (notConfirmedPass) {
      setShowInvalidConfirma(true);
      setIsButtonDisabled(true);
      setConfirmaMessage(
        <span>
          As senhas não coincidem
        </span>,
      );
      event.target.classList.add('wrong');
    }

    if (!notConfirmedPass) {
      setShowInvalidConfirma(false);
      event.target.classList.remove('wrong');
      event.target.classList.add('correct');
    }
  };

  const [checked, setChecked] = useState(false);

  const onCheckboxClick = () => {
    if (checked === false) {
      setChecked(true);
    }

    if (checked === true) {
      setChecked(false);
      setIsButtonDisabled(true);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePassword2 = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const getMask = (ev, model, setState, setValueInput) => {
    const originalValue = unMask(ev.target.value);
    const maskValue = masker(originalValue, [model]);
    setState(maskValue);
    setValueInput(maskValue);
  };

  useEffect(() => {
    if (!celNotLongEnough
      && !invalidCpfMessage
      && !notUserAge
      && !notValueDate
      && validPassword
      && !notConfirmedPass
      && checked
      && document.getElementById('gender').value !== '') {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [!celNotLongEnough, !invalidCpfMessage, !notUserAge, !notValueDate, validPassword, !notConfirmedPass, checked, generoState]);

  return (
    <div className="body">
      <div className="header-ig1">
        <img className="logo-ig" src={Logo} alt="" />
      </div>
      <header>
        <section className="header-section">
          <h1 className="ig-title">Todos os serviços iG, uma única conta!</h1>
          <h1 className="ig-instructions">Dados Pessoais</h1>
        </section>
      </header>
      <form className="needs-validation3" noValidate>
        <div className="form-row" id="third">
          <section className="steps steps3">
            <p className="step-atual" id="indice1">1</p>
            <div className="blue-line" />
            <p className="step-atual" id="indice2">2</p>
            <div className="blue-line-2" />
            <p className="step-atual" id="indice3">3</p>
          </section>
          <div className="row" id="col-step3">
            <div className="cel col-md-6 mb-3">
              <FloatingLabel htmlFor="celular" label="Celular com DDD" className="mb-3">
                <Form.Control
                  type="tel"
                  className="form-control"
                  id="celular"
                  value={valueCel}
                  maxLength="26"
                  minLength="25"
                  name={celular}
                  onChange={(event) => {
                    getMask(event, '(99) 99999-9999', setCelular, setValueCel);
                  }}
                  onKeyUp={(event) => {
                    validCellInput(event);
                  }}
                  placeholder="(21) 98786-5524"
                  required
                />
              </FloatingLabel>
              { showInvalidCel && (
                <span className="msg-error">
                  {celMessage}
                </span>
              )}
            </div>
            <div className="valid-feedback">É válido!</div>
            <div className="cpf col-md-6 mb-3" id="gender-div">
              <FloatingLabel htmlFor="cpf" label="CPF" className="mb-3">
                <Form.Control
                  type="tel"
                  className="form-control"
                  id="cpf"
                  name={cpf}
                  value={valueCpf}
                  onChange={(event) => {
                    getMask(event, '999.999.999-99', setCpf, setValueCpf);
                    validCpflInput(event);
                  }}
                  onKeyUp={(event) => {
                    validCpflInput(event);
                  }}
                  placeholder="CPF"
                  required
                />
              </FloatingLabel>
              { invalidCpfMessage && (
                <span className="msg-error">
                  {invalidCpfMessage}
                </span>
              )}
              { invalidCpfMessage2 && (
              <span className="msg-error-cpf">
                {invalidCpfMessage2}
              </span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="nasc col-md-6 mb-3">
              <FloatingLabel htmlFor="nascimento" label="Nascimento" className="mb-3">
                <Form.Control
                  type="text"
                  min="1900-01-01"
                  className="form-control"
                  dir="ltr"
                  id="nascimento"
                  name={nascimento}
                  value={nascimentoState}
                  onChange={(event) => {
                    getMask(event, '99/99/9999', setNascimento, setNascimento);
                    // setNascimento(event.target.value);
                    validNasclInput(event);
                  }}
                  onKeyUp={(event) => {
                    validNasclInput(event);
                  }}
                  required
                />
              </FloatingLabel>
              { showInvalidNasc && (
                <span className="msg-error">
                  {nascMessage}
                </span>
              )}
            </div>
            <div className="gender col-md-6 mb-3">
              <FloatingLabel htmlFor="genero" label="Gênero" className="">
                <Form.Select
                  className="genero"
                  id="gender"
                  name={genero}
                  value={generoState}
                  onChange={(event) => {
                    setGenero(event.target.value);
                    // validGender(event.target.value);
                  }}
                >
                  <option value="">Selecione o gênero&nbsp;</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                  <option value="Outros">Não informar</option>
                </Form.Select>
              </FloatingLabel>
            </div>
            <div className="valid-feedback">É válido!</div>
          </div>
          <div className="row">
            <div className="password col-md-6 mb-4">
              <FloatingLabel htmlFor="senha" label="Senha" className="mb-3">
                <input
                  type={passwordShown ? 'text' : 'password'}
                  className="form-control"
                  id="senha"
                  name={senha}
                  value={senha[0]}
                  onChange={(event) => {
                    setSenha(event.target.value);
                  }}
                  onKeyUp={(event) => {
                    validSenhaInput(event);
                  }}
                  placeholder="Senha"
                  required
                />
              </FloatingLabel>
              <div type="button" className="eye-icon2" onClick={togglePassword}>{passwordShown ? <FaEye /> : <FaEyeSlash />}</div>
              { showInvalidSenha && (
                <span className="msg-error-ps">
                  {senhaMessage}
                </span>
              )}
            </div>
            <div className="confirm-passord col-md-6 mb-4">
              <FloatingLabel htmlFor="confirma" label="Confirme a senha" className="mb-3">
                <input
                  type={confirmPasswordShown ? 'text' : 'password'}
                  className="form-control"
                  id="confirma"
                  name={confirma}
                  value={confirma[0]}
                  onChange={(event) => {
                    setConfirma(event.target.value);
                  }}
                  onKeyUp={(event) => {
                    validConfirmPassInput(event);
                  }}
                  placeholder="Confirme sua senha"
                  required
                />
              </FloatingLabel>
              <div type="button" className="eye-icon2" onClick={togglePassword2}>{confirmPasswordShown ? <FaEye /> : <FaEyeSlash />}</div>
              { showInvalidConfirma && (
                <span className="msg-error">
                  {confirmaMessage}
                </span>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="invalidCheck-3"
                required
                onClick={onCheckboxClick}
              />
              <label className="form-check-label" htmlFor="invalidCheck">
              &nbsp;Concordo com os&nbsp;
                <a href="https://institucional.ig.com.br/2020-11-05/termos-de-uso.html" target="_blank" rel="noreferrer">termos de uso</a>
              </label>
            </div>
          </div>
          <div className="btns">
            <Voltar ative={ative} isButtonDisabled={isButtonDisabled} show={show} />

            <section className="submit-comp btn-step3" style={{ width: ative[0] === 1 ? '100%' : '' }}>
              <button
                className="btn btn-primary"
                id="cadastrar"
                type="submit"
                disabled={isButtonDisabled[0]}
                onClick={handleSubmit}
              >
                CADASTRAR
              </button>
            </section>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Step3;

Step3.propTypes = {
  ative: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.func])).isRequired,
  cpf: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  celular: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  genero: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  nascimento: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  senha: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  confirma: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])).isRequired,
  show: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])).isRequired,
};
