import React from 'react';
import PropTypes from 'prop-types';
// import 'bootstrap/dist/css/bootstrap.min.css';

function Avancar({ ative, isButtonDisabled }) {
  const handleAvancar = () => {
    const setAtive = ative[1];
    const setIsButtonDisabled = isButtonDisabled[1];
    setAtive(ative[0] + 1);
    setIsButtonDisabled(true);
  };

  return (
    <footer className="step-footer" style={{ width: ative[0] === 1 ? '100%' : '' }}>
      <button type="submit" style={{ width: ative[0] === 1 ? '100%' : '191px' }} className=" avancar" disabled={isButtonDisabled[0]} onClick={handleAvancar}>AVANÇAR</button>
    </footer>
  );
}

export default Avancar;

Avancar.propTypes = {
  ative: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.func])).isRequired,
  isButtonDisabled:
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])).isRequired,
};
