/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Cookies from 'js-cookie';
import './NewStyle.css';
import Logo from './logo-igpet.png';
import Avancar from './Avancar';
import awsExports from '../../aws-exports';

Amplify.configure(awsExports);

function Step1({
  ative, fullName, email, isButtonDisabled,
}) {
  useEffect(() => {
    document.title = 'Entre na sua conta iG';
  }, []);
  const setFullName = fullName[1];
  const fullNameState = fullName[0];

  const setIsButtonDisabled = isButtonDisabled[1];

  const setEmail = email[1];
  const emailState = email[0];

  // estados para setar mensagem de erro do input Nome Completo
  const [showInvalidFullName, setShowInvalidFullName] = useState(false);
  const [fullNameMessage, setFullNameMessage] = useState('');

  // estados para setar mensagem de erro do input email
  const [showInvalidEmail, setShowInvalidEmail] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');

  // regex para validar email
  const re = /^[a-z0-9._]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

  // variaveis para validar o email informado
  const invalidEmail = !re.test(emailState);
  const validEmail = re.test(emailState);

  const validEmailInput = async (event) => {
    if (invalidEmail) {
      setShowInvalidEmail(true);
      setIsButtonDisabled(true);
      setEmailMessage(
        <span>
          Informar email válido
        </span>,
      );
      event.target.classList.add('wrong');
    }

    if (validEmail) {
      setShowInvalidEmail(false);
      event.target.classList.remove('wrong');
      event.target.classList.add('correct');
      try {
        await Auth.signUp(emailState, '123');
      } catch (error) {
        if (error.message === 'An account with the given email already exists.') {
          setShowLogin(true);
          setIsButtonDisabled(true);
          setShowInvalidEmail(true);
          setEmailMessage(
            <span>
              Já existe uma conta com o e-mail informado.
            </span>,
          );
        } else {
          setShowLogin(false);
          setIsButtonDisabled(false);
        }
      }
    }
  };

  // regex para validar o nome completo
  const checkFullName = /[A-Z][a-z].* [A-Z][a-z].*/;

  // variaveis para validar o nome completo informado
  const invalidFullname = !checkFullName.test(fullNameState);
  const validFullName = checkFullName.test(fullNameState);

  const validFullNameInput = (event) => {
    if (invalidFullname) {
      setShowInvalidFullName(true);
      setIsButtonDisabled(true);
      setFullNameMessage(
        <span>
          Informar Nome e Sobrenome
        </span>,
      );
      event.target.classList.add('wrong');
    }

    if (validFullName) {
      setShowInvalidFullName(false);
      event.target.classList.remove('wrong');
      event.target.classList.add('correct');
    }
  };

  useEffect(() => {
    if (validFullName && validEmail) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [validFullName, validEmail]);

  // if (validFullName && validEmail) {
  //   setIsButtonDisabled(false);
  // }

  const [phoneOrEmail, setPhoneOrEmail] = useState();

  useEffect(() => {
    const accountToKeep = Cookies.get('accountToKeep');
    if (accountToKeep !== undefined) {
      document.getElementById('invalidCheck').checked = true;
      setPhoneOrEmail(accountToKeep);
    }
  }, []);

  const [password, setPassword] = useState();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [passwordShown, setPasswordShown] = useState(false);
  const eye = <FontAwesomeIcon icon={faEye} />;
  const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const keepUserAccess = () => {
    if (document.getElementById('invalidCheck').checked === true) {
      document.cookie = `accountToKeep=${phoneOrEmail};max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
    } else {
      document.cookie = 'accountToKeep=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=ig.com.br';
    }
  };

  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const woocomerce = Cookies.get('woocomerce');

  const accountCookie = Cookies.get('user');

  const cpfCookie = Cookies.get('cpf');
  const birthdateCookie = Cookies.get('birthdate');
  const fullNameCookie = Cookies.get('fullName');
  const phoneNumberCookie = Cookies.get('phone_number');
  const zipCodeCookie = Cookies.get('zipCode');
  const streetCookie = Cookies.get('street');
  const districtCookie = Cookies.get('district');
  const cityCookie = Cookies.get('city');
  const stateCookie = Cookies.get('state');
  const numberCookie = Cookies.get('number');
  const complementCookie = Cookies.get('complement');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    try {
      Hub.listen('auth', async (data) => {
        // eslint-disable-next-line no-shadow
        const { email } = data.payload.data.attributes;
        const { name } = data.payload.data.attributes;
        const { birthdate } = data.payload.data.attributes;
        // eslint-disable-next-line camelcase
        const { phone_number } = data.payload.data.attributes;
        const cpf = data.payload.data.attributes['custom:cpf'];
        const zipCode = data.payload.data.attributes['custom:zipcode'];
        const street = data.payload.data.attributes['custom:street'];
        const city = data.payload.data.attributes['custom:city'];
        const state = data.payload.data.attributes['custom:state'];
        const district = data.payload.data.attributes['custom:district'];
        const number = data.payload.data.attributes['custom:number'];
        const complement = data.payload.data.attributes['custom:complement'];
        const timerCookie = `max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;

        if (accountCookie === undefined) {
          document.cookie = `user=${email}; ${timerCookie}`;
        }
        if (fullNameCookie === undefined) {
          document.cookie = `fullName=${name}; ${timerCookie}`;
        }
        if (birthdateCookie === undefined) {
          document.cookie = `birthdate=${birthdate}; ${timerCookie}`;
        }
        if (cpfCookie === undefined) {
          document.cookie = `cpf=${cpf}; ${timerCookie}`;
        }
        if (phoneNumberCookie === undefined) {
          // eslint-disable-next-line camelcase
          document.cookie = `phone_number=${phone_number}; ${timerCookie}`;
        }
        if (zipCodeCookie === undefined) {
          document.cookie = `zipCode=${zipCode}; ${timerCookie}`;
        }
        if (streetCookie === undefined) {
          document.cookie = `street=${street}; ${timerCookie}`;
        }
        if (cityCookie === undefined) {
          document.cookie = `city=${city}; ${timerCookie}`;
        }
        if (stateCookie === undefined) {
          document.cookie = `state=${state}; ${timerCookie}`;
        }
        if (districtCookie === undefined) {
          document.cookie = `district=${district}; ${timerCookie}`;
        }
        if (numberCookie === undefined) {
          document.cookie = `number=${number}; ${timerCookie}`;
        }
        if (complementCookie === undefined) {
          document.cookie = `complement=${complement}; ${timerCookie}`;
        }
      });
      keepUserAccess();
      await Auth.signIn(phoneOrEmail, password);
      if (woocomerce === undefined) {
        navigate('/homepage');
      } else {
        window.location.href = 'https://pet.ig.com.br/finalizar-compra-v2/';
      }
    } catch (e) {
      console.log(e.message);
      setHasError(true);
      if (e.message === 'User does not exist.') {
        setErrorMessage('Usuario inexistente');
      }
      if (e.message === 'Incorrect username or password.') {
        setErrorMessage('Senha ou email incorretos');
      }
      if (e.message === 'Custom auth lambda trigger is not configured for the user pool.') {
        setErrorMessage('Informe a senha');
      }
      if (e.message === 'Username cannot be empty') {
        setErrorMessage('Informe o Email');
      }
      if (e.message === 'User pool client 5v9anc7ekduakd6bd2c1tn74vn does not exist.') {
        setErrorMessage('Usuario inexistente. Realizar cadastro');
      }
      if (e.message === 'User is not confirmed.') {
        setErrorMessage('Usuário com código não confirmado.');
      }
    }
    setValidated(true);
  };

  // Estado para alterar os inputs de telas no mobile
  const [showInput, setShowInput] = useState(true);

  // Estado para identificar tamando da tela (mobile o desk)
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);

  // UseEffect que aplica efeito ao alterar tamanho de tela (mobile o desk)
  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);

    window.addEventListener('resize', resizeW); // Update the width on resize

    return () => window.removeEventListener('resize', resizeW);
  });

  return (
    <div className="main-ig">
      <div className="header-main">
        <img src={Logo} alt="" />
        <h1 className="header-title">Cadastre-se ou efetue seu login para concluir sua compra!</h1>
      </div>
      <div className="row-ig" style={{ background: showInput && deviceSize <= 767 ? '#FAFAFA' : '#F27166' }}>
        <div className="login-row">
          <div className="title-login-desk">
            <h1 className="title-desk1">Já tem cadastro no iG?</h1>
            <h3 className="text-desk1">Coloque seu email e senha, e faremos o resto por você!</h3>
          </div>
          <div className="title-login-mobile" style={{ display: showInput && deviceSize <= 767 ? 'block' : 'none' }}>
            <h1 className="title-mobile2">Efetue seu login para efetuar sua compra!</h1>
            <h3 className="text-mobile2">Coloque seu email e senha e faremos o resto por você!</h3>
          </div>

          <div className="btn-select">
            <button className="new-customer" type="button" onClick={() => setShowInput(false)}>Novo Cliente</button>
            <button className="customer" type="button" onClick={() => setShowInput(true)}>Já sou Cliente</button>
          </div>
          <div className="login-div">
            <div className="form-login" style={{ display: showInput && deviceSize <= 767 ? 'block' : showInput && deviceSize > 767 ? 'block' : 'none' }}>
              <Form noValidate validated={!!validated} onSubmit={handleSubmit}>
                <div>
                  <Form.Control
                    className="input-email-login"
                    name={phoneOrEmail}
                    onFocus={() => setHasError(false)}
                    onChange={(event) => setPhoneOrEmail(event.target.value)}
                    type="text"
                    // value={phoneOrEmail}
                    placeholder="E-mail"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    <span className="invalid-field1">Campo obrigatório!</span>
                  </Form.Control.Feedback>
                </div>
                <br />
                <div>
                  <div>
                    <i className="magic-eye" onClick={togglePassword}>{passwordShown ? eyeSlash : eye}</i>
                    <Form.Control
                      className="input-senha-login"
                      name={password}
                      onChange={(event) => setPassword(event.target.value)}
                      placeholder="Senha"
                      type={passwordShown ? 'text' : 'password'}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      <span className="invalid-field2">Campo obrigatório!</span>
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div>
                  { hasError && (
                  <span className="msg-error-signin">
                    {errorMessage}
                  </span>
                  )}
                  <br />
                  <div className="keep-info">
                    <input
                      type="checkbox"
                      value=""
                      id="invalidCheck"
                      required
                    />
                    <label htmlFor="invalidCheck">
                      <p className="keep-access">&nbsp;Guardar acesso</p>
                    </label>
                    <Link to="/recuperasenha">
                      <p className="forgot-pass">Esqueceu a senha?</p>
                    </Link>
                  </div>
                  <div>
                    <button onClick={handleSubmit} className="login-btn" type="button"><b>ENTRAR</b></button>
                  </div>
                  <div>
                    <p className="txt-center">
                      Ao continuar com o acesso, você concorda
                      <br />
                      com a nossa&nbsp;
                      <a className="ahref" href="https://institucional.ig.com.br/2020-11-05/politica-de-privacidade-ig.html" target="_blank" rel="noreferrer">Política de Privacidade</a>
                    </p>
                  </div>
                </div>
                <br />
                <br />
              </Form>
            </div>
          </div>
        </div>
        <div className="signup-row">
          <div className="title-signup-desk">
            <h1 className="title-desk2">Ainda não é cliente?</h1>
            <h3 className="text-desk2">
              Coloque seu nome completo e e-mail
              {' '}
              <br />
              {' '}
              preferido e finalize sua compra!
            </h3>
          </div>
          <div className="title-signup-mobile">
            <h1 className="title-singup-mobile2" style={{ display: showInput && deviceSize <= 767 ? 'none' : 'block' }}>Cadastre-se para concluir sua compra!</h1>
            <h3 className="text-singup-mobile2" style={{ display: showInput && deviceSize <= 767 ? 'none' : 'block' }}>Coloque seu nome completo e o seu e-mail preferido e finalize sua compra!</h3>
          </div>
          <div className="btn-select" style={{ display: showInput && deviceSize <= 767 ? 'none' : 'block' }}>
            <button className="new-customer" type="button" onClick={() => setShowInput(false)}>Novo Cliente</button>
            <button className="customer" type="button" onClick={() => setShowInput(true)}>Já sou Cliente</button>
          </div>
          <div className="form-signup" style={{ display: showInput && deviceSize <= 767 ? 'none' : showInput && deviceSize > 767 ? 'block' : 'block' }}>
            <div>
              <div>
                <Form.Control
                  className="input-fullname"
                  aria-label="Nome Completo"
                  type="text"
                  value={fullName[0]}
                  name={fullName}
                  placeholder="Nome Completo"
                  onChange={(event) => {
                    setFullName(event.target.value.replace(/(^| )[a-z]/ig, (m) => m.toUpperCase()));
                  }}
                  onKeyUp={(event) => {
                    validFullNameInput(event);
                  }}
                  required
                />
                { showInvalidFullName && (
                <span className="error-step1">
                  {fullNameMessage}
                </span>
                )}
              </div>
            </div>
            <div>
              <div>
                <Form.Control
                  className="input-email"
                  aria-label="Email"
                  type="email"
                  value={email[0]}
                  name={email}
                  placeholder="E-mail"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  onKeyUp={(event) => {
                    validEmailInput(event);
                  }}
                  required
                />
                { showInvalidEmail && (
                <span className="error-step1">
                  {emailMessage}
                </span>
                )}
              </div>
              <br />
            </div>
            { showLogin === true && (
            <a
              className="enter"
              href="/"
              style={{
                width: '100%',
                textTransform: 'uppercase',
                textAlign: 'center',
                alignItems: 'center',
                padding: '5px',
              }}
            >
              Entrar
            </a>
            )}
            { showLogin === false && (
            <div>
              <Avancar ative={ative} isButtonDisabled={isButtonDisabled} />
            </div>
            )}
            <div>
              <p className="txt-center">
                Ao continuar com o acesso, você concorda
                <br />
                com a nossa&nbsp;
                <a className="ahref" href="https://institucional.ig.com.br/2020-11-05/politica-de-privacidade-ig.html" target="_blank" rel="noreferrer">Política de Privacidade</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step1;

Step1.defaultProps = {
  email: [''],
};

Step1.propTypes = {
  ative: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.func])),
  fullName: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
  email: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
  isButtonDisabled:
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.func])),
};
