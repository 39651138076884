import React from 'react';
import Rotas from './routes/Routes';

function App() {
  return (
    <Rotas />
  );
}

export default App;
