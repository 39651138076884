/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import Eye from './eye.png';
import awsExports from '../../aws-exports';
import './StyleCodeVerification.css';
import Logo from './iglogo.png';

Amplify.configure(awsExports);

function VerificationCode() {
  useEffect(() => {
    document.title = 'Confirmação de código';
  }, []);

  const [code, setCode] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();

  const formulario = useRef({});

  const emailToConfirm = Cookies.get('user');
  const forgotPass = Cookies.get('forgotPass');
  const forgotPassUser = Cookies.get('forgotPassUser');

  const [newPass, setNewPass] = useState('');
  const [newPassMessage, setNewPassMessage] = useState('');

  const [newPassConfirm, setNewPassConfirm] = useState('');
  const [confirmPassMessage, setConfirmPassMessage] = useState('');

  const re = /^(?=.*[A-Z])(?=.*[!#@$%&])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;

  const [validPassword, setValidPassword] = useState(false);
  const [validCode, setValidCode] = useState(false);

  useEffect(() => {
    if (forgotPass === undefined) {
      if (validCode) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
    if (forgotPass !== undefined) {
      if (validPassword && validCode) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }
  }, [validPassword, validCode]);

  const filterPass = (event) => {
    const { value } = event.target;
    setNewPass(value);
    if (!re.test(value)) {
      setNewPassMessage(
        'No mínimo 8 dígitos, números, letras maiúsculas e caracteres  especiais.',
      );
      event.target.classList.add('wrong');
    } else {
      setNewPassMessage('');
      event.target.classList.remove('wrong');
      event.target.classList.add('correct');
    }
  };

  const filterConfirmPass = (event) => {
    const { value } = event.target;
    setNewPassConfirm(value);
    if (newPass === value) {
      setConfirmPassMessage('');
      setValidPassword(true);
    } else {
      setConfirmPassMessage('As senhas não coincidem');
      event.target.classList.remove('wrong');
      event.target.classList.add('correct');
      setValidPassword(false);
    }
  };

  const handlePaste = (e) => {
    const clipboardContent = e.clipboardData.getData('Text');
    // Percorre os elementos do formulário e
    // altera a propriedade value de cada um
    // para o último elemento do array,
    // através do método pop (destrutivo);
    [...e.currentTarget.elements].forEach((input, i) => {
      // eslint-disable-next-line no-param-reassign
      input.value = clipboardContent[i];
    });
    // Atualiza o estado com os valores do clipboard
    // fatiados de acordo com o número de inputs
    setCode(clipboardContent.slice(0, 6));
    if (!forgotPass) {
      setIsButtonDisabled(false);
      e.target.nextSibling.focus();
    }
  };

  const handleEnter = (event) => {
    const inputValues = Object.values(formulario.current)
      .slice(0, 6)
      .reduce((acc, element) => acc + element.value, '');
    if (inputValues.length === 6) {
      setValidCode(true);
    } else {
      setValidCode(false);
    }
    if (event.target.previousElementSibling && event.target.value === '') {
      event.target.previousSibling.focus();
      setCode(inputValues);
      return;
    }
    if (event.target.nextSibling) {
      event.target.nextSibling.focus();
    }
    setCode(inputValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (forgotPass !== undefined) {
      document.cookie = `woocomerce=false; max-age=${
        60 * 60 * 24 * 180
      }; path=/; domain=ig.com.br`;
      await Auth.forgotPasswordSubmit(forgotPassUser, code, newPass)
        .then(() => {
          navigate('/confirmcode');
        })
        .catch((err) => {
          setHasError(true);
          if (
            err.message
            === 'Invalid verification code provided, please try again.'
          ) {
            setErrorMessage('Código inválido');
          }
        });
    } else {
      try {
        await Auth.confirmSignUp(emailToConfirm, code);
        Cookies.remove('forgotPass');
        Cookies.remove('forgotPassUser');
        navigate('/confirmcode');
      } catch (e) {
        console.log('erro ao confirmar email:', e.message);
        setHasError(true);
        if (e.message.includes('is not authorized to perform')) {
          navigate('/confirmcode');
        }
        if (
          e.message === 'User cannot be confirmed. Current status is CONFIRMED'
        ) {
          setErrorMessage(
            'Status atual do usuário já está CONFIRMADO, você será redirecionado!',
          );
          navigate('/confirmcode');
        }
        if (e.message === 'Confirmation code cannot be empty') {
          setErrorMessage('Os campos não podem estar vazios');
        }
        if (e.message === 'Username cannot be empty') {
          setErrorMessage('Campo email é OBRIGATÓRIO');
        }
        if (e.message === 'Username/client id combination not found.') {
          setErrorMessage('Código não corresponde ao e-mail informado');
        }
        if (
          e.message === 'Invalid verification code provided, please try again.'
        ) {
          setErrorMessage('Código inválido');
        }
      }
    }
  };

  const handleResend = async (event) => {
    event.preventDefault();
    try {
      await Auth.resendSignUp(emailToConfirm);
    } catch (e) {
      console.log('erro ao reenviar código:', e.message);
      setHasError(true);
      if (e.message === 'User is already confirmed.') {
        setErrorMessage('Usuario com status já CONFIRMADO');
      }
      if (e.message === 'Username/client id combination not found.') {
        setErrorMessage('E-mail informado não encontrado');
      }
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePassword2 = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  return (
    <main className="main-header">
      <div className="header-code">
        <img className="logo-ig" src={Logo} alt="" />
      </div>
      <section className="head-section">
        <h1 className="ig-service">Todos os serviços iG, uma única conta!</h1>
        {forgotPass && (
          <h1 className="ig-code">
            Para redefinir sua senha, insira o código de verificação de 6
            dígitos que enviamos em seu e-mail:
          </h1>
        )}
        {!forgotPass && (
          <h1 className="ig-code">
            Para confirmar sua conta, insira o código de verificação de 6
            dígitos que enviamos em seu e-mail:
          </h1>
        )}
      </section>
      <form ref={formulario} className="user-input" onPaste={handlePaste}>
        <input className="box" type="tel" maxLength={1} onKeyUp={handleEnter} />
        <input className="box" type="tel" maxLength={1} onKeyUp={handleEnter} />
        <input className="box" type="tel" maxLength={1} onKeyUp={handleEnter} />
        <input className="box" type="tel" maxLength={1} onKeyUp={handleEnter} />
        <input className="box" type="tel" maxLength={1} onKeyUp={handleEnter} />
        <input className="box" type="tel" maxLength={1} onKeyUp={handleEnter} />
      </form>
      {hasError && <span className="msg-error-code-s">{errorMessage}</span>}
      {forgotPass && (
        <div className="inputs-code">

          <div className="pass">
            <FloatingLabel
              htmlFor="newPass"
              label="Nova senha"
              style={{
                display: 'flex',
                marginTop: '20px',
                marginBottom: '20px',
                color: '#7E7E7E',
              }}
            >
              <Form.Control
                className="form-control"
                type={passwordShown ? 'text' : 'password'}
                id="newPass"
                name="newPass"
                value={newPass}
                onChange={filterPass}
                required
                style={{ width: '256px' }}
              />
              <div type="button" className="eye-icon3" onClick={togglePassword}>
                {passwordShown ? <FaEye /> : <FaEyeSlash />}
              </div>
            </FloatingLabel>

            {newPassMessage && (
              <span className="msg-error-code">{newPassMessage}</span>
            )}
          </div>

          <br />
          <div className="confirm-pass">
            <FloatingLabel
              htmlFor="newPassConfirm"
              label="Confirme sua senha"
              style={{
                display: 'flex',
                flexDirection: 'column',
                color: '#7E7E7E',
              }}
            >
              <Form.Control
                type={confirmPasswordShown ? 'text' : 'password'}
                name="newPassConfirm"
                value={newPassConfirm}
                onChange={filterConfirmPass}
                required
                style={{ width: '256px' }}
              />
            </FloatingLabel>
            <div type="button" className="eye-icon4" onClick={togglePassword2}>
              {confirmPasswordShown ? <FaEye /> : <FaEyeSlash />}
            </div>
            {newPassConfirm && (
              <span className="msg-error-code-2">{confirmPassMessage}</span>
            )}
          </div>
        </div>
      )}
      <div className="btns-code">
        <button
          className="btn btn-primary confirm-btn"
          type="button"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          VALIDAR
        </button>
        {!forgotPass && (
          <button
            className="btn btn-success resend-btn"
            type="button"
            onClick={handleResend}
          >
            Reenviar código
          </button>
        )}
      </div>
    </main>
  );
}

export default VerificationCode;
