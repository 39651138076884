import React, { useEffect } from 'react';
import './StyleRecuperaSenha.css';

function PageRecovery() {
  useEffect(() => {
    document.title = 'Recuperação de senha';
  }, []);

  return (
    <div className="recupera-senha">
      <img src="https://i0.statig.com.br/sass-canais/ig/images/home/logo_iguinho_home.png" alt="" />
      <h1>Enviamos um código para o e-mail informado!</h1>
      <p>Abra o link que enviamos por e-mail e redefina a sua senha.</p>
    </div>
  );
}

export default PageRecovery;
