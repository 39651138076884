import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './StyleTrocaSenha.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

function PageRecovery() {
  useEffect(() => {
    document.title = 'Sucesso!';
  }, []);

  return (
    <div className="troca-senha">
      <img src="https://i0.statig.com.br/sass-canais/ig/images/home/logo_iguinho_home.png" alt="" />
      <h1>Redefinição de senha bem-sucedida!</h1>
      <p>Agora você pode usar a nova senha para entrar na sua conta!</p>
      <Link to="/">
        <button type="button" className="btn btn-primary btn-sm">Entrar</button>
      </Link>
    </div>
  );
}

export default PageRecovery;
