/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
// import Cookies from 'universal-cookie';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import Step1 from '../components/cadastro/Step1';
import Step2 from '../components/cadastro/Step2';
import Step3 from '../components/cadastro/Step3';

// import VerificationCode from './verification_code_page/PageVerificationCode';
import awsExports from '../aws-exports';

// const cookies = new Cookies();

Amplify.configure(awsExports);

function Cadastro() {
  useEffect(() => {
    document.title = 'Crie sua conta no iG';
  }, []);

  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [bairro, setBairro] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [celular, setCelular] = useState('');
  const [cpf, setCpf] = useState('');
  const [genero, setGenero] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [senha, setSenha] = useState('');
  const [confirma, setConfirma] = useState('');

  const [ativeStep, setAtiveStep] = useState(1);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showAdress, setShowAdress] = useState(false);
  const [showNotKnow, setShowNotKnow] = useState(true);

  const numberInt = `+55${celular}`;
  const numberIntUnMask = numberInt.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '');

  const nascSplit = nascimento.split('/');
  const nascFormat = `${nascSplit[0]}/${nascSplit[1]}/${nascSplit[2]}`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await Auth.signUp(
        {
          username: email,
          password: senha,
          attributes: {
            email,
            name: fullName,
            'custom:zipcode': cep,
            'custom:city': cidade,
            'custom:state': estado,
            'custom:street': endereco,
            'custom:district': bairro,
            'custom:number': numero,
            'custom:complement': complemento,
            phone_number: numberIntUnMask,
            'custom:cpf': cpf,
            birthdate: nascFormat,
            gender: genero,
          },
        },
      );
      Cookies.remove('forgotPass');
      Cookies.remove('forgotPassUser');
      navigate('/codeverification');

      document.cookie = `user=${email}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `fullName=${fullName}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `birthdate=${nascFormat}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `cpf=${cpf}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `phone_number=${numberIntUnMask}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `zipCode=${cep}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `street=${endereco}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `district=${bairro}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `city=${cidade}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `state=${estado}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `number=${numero}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
      document.cookie = `complement=${complemento}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
    } catch (e) {
      console.log(e);
      setHasError(true);
      if (e.message === 'Username cannot be empty') setErrorMessage('Todos os campos precisam ser preenchidos.');
      if (e.message === 'Password cannot be empty') setErrorMessage('Todos os campos precisam ser preenchidos.');
      if (e.message === 'Password not long enough') setErrorMessage('A senha digitada é muito pequena.');
      if (e.message === 'Invalid email address format.') setErrorMessage('O formato do email digitado não é válido.');
    }
  };

  let StepRender = <Step1 />;
  if (ativeStep === 1) {
    StepRender = (
      <Step1
        ative={[ativeStep, setAtiveStep]}
        fullName={[fullName, setFullName]}
        email={[email, setEmail]}
        isButtonDisabled={[isButtonDisabled, setIsButtonDisabled]}
      />
    );
  }
  if (ativeStep === 2) {
    StepRender = (
      <Step2
        ative={[ativeStep, setAtiveStep]}
        cep={[cep, setCep]}
        cidade={[cidade, setCidade]}
        show={[showAdress, setShowAdress]}
        estado={[estado, setEstado]}
        endereco={[endereco, setEndereco]}
        bairro={[bairro, setBairro]}
        numero={[numero, setNumero]}
        showNotKnowCEP={[showNotKnow, setShowNotKnow]}
        complemento={[complemento, setComplemento]}
        isButtonDisabled={[isButtonDisabled, setIsButtonDisabled]}
      />
    );
  }
  if (ativeStep === 3) {
    StepRender = (
      <Step3
        ative={[ativeStep, setAtiveStep]}
        show={[showAdress, setShowAdress]}
        celular={[celular, setCelular]}
        cpf={[cpf, setCpf]}
        genero={[genero, setGenero]}
        nascimento={[nascimento, setNascimento]}
        senha={[senha, setSenha]}
        confirma={[confirma, setConfirma]}
        handleSubmit={handleSubmit}
        isButtonDisabled={[isButtonDisabled, setIsButtonDisabled]}
      />
    );
  }

  return (
    <div className="ig-up">
      { StepRender }
      { hasError && (
      <span className="msg-error">
        {errorMessage}
      </span>
      )}
    </div>
  );
}

export default Cadastro;
