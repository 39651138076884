/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Amplify, Auth } from 'aws-amplify';
import './StyleRecuperaSenha.css';
import { Form } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import awsExports from '../../aws-exports';
import Logo2 from './iglogo.png';
import Seta from './arrow.png';

Amplify.configure(awsExports);

function SenhaRecovery() {
  useEffect(() => {
    document.title = 'Recuperação de senha';
  }, []);

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showInvalidEmail, setShowInvalidEmail] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');

  const isInputValid = () => {
    const re2 = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

    const validEmail = re2.test(email);

    if (!validEmail) {
      setIsButtonDisabled(true);
    }

    if (validEmail) {
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => { isInputValid(); }, [email]);

  const handleClick = async (event) => {
    event.preventDefault();
    try {
      await Auth.signUp(email, '123');
    } catch (error) {
      try {
        if (error.message === 'An account with the given email already exists.') {
          await Auth.forgotPassword(email);
          Cookies.set('forgotPassUser', email);
          Cookies.set('forgotPass', 'yes');
          navigate('/codeverification');
        } else {
          setIsButtonDisabled(true);
          setShowInvalidEmail(true);
          setEmailMessage(
            <span>
              Este e-mail não está cadastrado.
            </span>,
          );
        }
      } catch (e) {
        console.log(e.message);
        setHasError(true);
        if (e.message === 'Username/client id combination not found.') {
          setErrorMessage('Email não encontrado');
        }
      }
    }
  };

  return (
    <main>
      <div className="sidenav">
        <div className="login-main-text">
          <img src={Logo2} alt="" className="logo" />
        </div>
      </div>
      <div className="main-login" id="main-recov">
        <div className="login-form-senha-2">
          <p className="title-redefine-senha">Insira seu e-mail para redefinir sua senha&nbsp;&nbsp;</p>
        </div>
        <div className="col-md-6 col-sm-12 login-user">
          <div className="login-form-senha">
            <Link to="/">
              <img src={Seta} alt="" className="seta1" />
            </Link>
            <h1 className="text-h1">REDEFINIR SENHA</h1>
            <Form noValidate className="form-login-senha">
              <div>
                <Form.Control
                  id="login"
                  name="phoneOrEmail"
                  type="text"
                  value={email}
                  className="form-control"
                  placeholder="E-mail"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  required
                />
                { showInvalidEmail && (
                <span className="msg-error-step1">
                  {emailMessage}
                </span>
                )}
                { hasError && (
                  <span className="msg-error-recov">
                    {errorMessage}
                  </span>
                )}
                <br />
                <div>
                  <button type="button" className="btn-login-senha" onClick={handleClick} disabled={isButtonDisabled}><b>AVANÇAR</b></button>
                </div>
                <Form.Control.Feedback className="invalid-feedback" type="invalid">
                  <span className="inv-feed">Campo obrigatório!</span>
                </Form.Control.Feedback>
              </div>
              <br />
            </Form>
          </div>
          <Link to="/">
            <img src={Seta} alt="" className="seta" />
          </Link>
        </div>
      </div>
      <br />
    </main>
  );
}

export default SenhaRecovery;
