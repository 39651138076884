import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// rota de componentes
import Login from '../components/login/Login';
import Cadastro from '../pages/Cadastro';
import RecuperaSenha from '../components/recupera_senha/RecuperaSenha';
import TrocaSenha from '../components/troca_senha/TrocaSenha';
import VerificationCode from '../components/verification_code/PageVerificationCode';

// rota de pages
import PageRecuperaSenha from '../pages/page_recupera_senha/PageRecuperaSenha';
import PageTrocaSenha from '../pages/page_troca_senha/PageTrocaSenha';
import HomePage from '../pages/home_page/HomePage';
import PageVerificationCode from '../pages/verification_code_page/PageVerificationCode';
import Gtm from '../components/metas/Gtm';
import Tags from '../components/metas/Tags';

function Rotas() {
  const descCadastro = 'Crie sua conta iG e fique por dentro das notícias, produtos e novidades.';
  const desc = 'Entre na sua conta iG e fique por dentro das notícias, produtos e novidades.';
  const keywords = 'redefina sua senha, redefinição de senha, trocar minha senha, trocar senha, conta ig';
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={(
            // Rota Cadastro não será mais acessada pelo path "/cadastro"
            // A partir de agora o acesso será através do path "/"
            // Onde podemos fazer login e cadastro na mesma tela
            <>
              <Cadastro />
              <Gtm />
              <Tags
                ogTitle="Crie sua conta - iG"
                ogDescription={descCadastro}
                ogUrl="https://conta-beta.ig.com.br"
                ogSiteName={descCadastro}
                description={descCadastro}
                keywords="cadastro, cadastre-se, criar conta, crie sua conta, conta ig"
              />
            </>
          )}
        />
        <Route
          path="/"
          element={(
            <>
              <Login />
              <Gtm />
              <Tags
                ogTitle="Entrar na sua conta - iG"
                ogDescription={desc}
                ogUrl="https://conta-beta.ig.com.br"
                ogSiteName={desc}
                description={desc}
                keywords="entrar, entrar na conta, login, fazer login, conta ig"
              />
            </>
          )}
        />
        <Route
          exact
          path="/recuperasenha"
          element={(
            <>
              <RecuperaSenha />
              <Gtm />
              <Tags
                ogTitle="Recupere sua senha - iG"
                ogDescription={desc}
                ogUrl="https://conta-beta.ig.com.br/recuperasenha"
                ogSiteName={desc}
                description={desc}
                keywords="recupere sua senha, recuperação de senha, esqueci minha senha, esqueceu sua senha, conta ig"
              />
            </>
          )}
        />
        <Route
          exact
          path="/redefinesenha"
          element={(
            <>
              <PageRecuperaSenha />
              <Gtm />
              <Tags
                ogTitle="Você recebeu um código de verificação! - iG"
                ogDescription={desc}
                ogUrl="https://conta-beta.ig.com.br/redefinesenha"
                ogSiteName={desc}
                description={desc}
                keywords={keywords}
              />
            </>
          )}
        />
        {/* Rota Troca Senha não está em uso */}
        <Route
          exact
          path="/trocasenha"
          element={(
            <>
              <TrocaSenha />
              <Gtm />
              <Tags
                ogTitle="Troca de senha - iG"
                ogDescription={desc}
                ogUrl="https://conta-beta.ig.com.br/trocasenha"
                ogSiteName={desc}
                description={desc}
                keywords={keywords}
              />
            </>
          )}
        />
        <Route
          exact
          path="/senhaupdated"
          element={(
            <>
              <PageTrocaSenha />
              <Gtm />
              <Tags
                ogTitle="Sua senha foi alterada! - iG"
                ogDescription={desc}
                ogUrl="https://conta-beta.ig.com.br/senhaupdated"
                ogSiteName={desc}
                description={desc}
                keywords={keywords}
              />
            </>
          )}
        />
        <Route
          exact
          path="/homepage"
          element={(
            <>
              <HomePage />
              <Gtm />
              {/* <Tags
                ogTitle="Sua senha foi alterada! - iG"
                ogDescription={desc}
                ogUrl="https://conta-beta.ig.com.br/senhaupdated"
                ogSiteName={desc}
                description={desc}
                keywords="redefina sua senha,
                redefinição de senha, trocar minha senha, trocar senha, conta ig"
              /> */}
            </>
          )}
        />
        <Route
          exact
          path="/codeverification"
          element={(
            <>
              <VerificationCode />
              <Gtm />
              <Tags
                ogTitle="Confirmação de email - iG"
                ogDescription={desc}
                ogUrl="https://conta-beta.ig.com.br/codeverification"
                ogSiteName={desc}
                description={desc}
                keywords="confirme seu email, confirmação de email, código de verificação, verificação, recupere sua senha, recuperação de senha, esqueci minha senha, esqueceu sua senha, conta ig"
              />
            </>
          )}
        />
        <Route
          exact
          path="/confirmcode"
          element={(
            <>
              <PageVerificationCode />
              <Gtm />
              <Tags
                ogTitle="Sua senha foi redefinida! - iG"
                ogDescription={desc}
                ogUrl="https://conta-beta.ig.com.br/confirmcode"
                ogSiteName={desc}
                description={desc}
                keywords={keywords}
              />
            </>
          )}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Rotas;
