/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import './StyleTrocaSenha.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import awsExports from '../../aws-exports';

Amplify.configure(awsExports);

function TrocaSenha() {
  useEffect(() => {
    document.title = 'Redefina sua senha';
  }, []);

  // função que valida os inputs password e confirmPassword
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Campo obrigatório')
      .min(8, 'Senha deve conter no mínimo 8 caracteres'),
    confirmPassword: Yup.string()
      .required('Campo obrigatório')
      .oneOf([Yup.ref('password')], 'As senhas não coincidem'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  // funções que geram o form com o useForm()
  const {
    register,
    handleSubmit,
    formState,
  } = useForm(formOptions);

  const { errors } = formState;

  function onSubmit(data) {
    // display form data on success
    console.log(JSON.stringify(data, null, 4));
    return false;
  }

  return (
    <div className="mainDiv">
      <div className="cardStyle">
        <form onSubmit={handleSubmit(onSubmit)}>
          <img src="https://i0.statig.com.br/sass-canais/ig/images/home/logo_iguinho_home.png" id="signupLogo" alt="" />
          <h2 className="formTitle">
            Redefinir senha
          </h2>
          <div className="form-row">
            <div className="form-group col">
              <label>Senha</label>
              <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
              <div className="invalid-feedback">{errors.password?.message}</div>
            </div>
            <div className="form-group col">
              <label>Confirme Senha</label>
              <input name="confirmPassword" type="password" {...register('confirmPassword')} className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />
              <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
            </div>
          </div>
          <div className="buttonWrapper">
            <button type="submit" id="submitButton" className="btn btn-primary mr-1">Atualizar</button>
            {/* <button type="button" onClick={() => reset()} className="btn btn-secondary">Reset</button> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default TrocaSenha;
