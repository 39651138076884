/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Amplify, Auth, Hub } from 'aws-amplify';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './StyleLogin.css';
import { Form } from 'react-bootstrap';
import Cookies from 'js-cookie';
import awsExports from '../../aws-exports';
import Logo2 from './iglogo.png';

Amplify.configure(awsExports);

function Login() {
  useEffect(() => {
    document.title = 'Entre na sua conta iG';
  }, []);

  const [phoneOrEmail, setPhoneOrEmail] = useState();

  useEffect(() => {
    const accountToKeep = Cookies.get('accountToKeep');
    if (accountToKeep !== undefined) {
      document.getElementById('invalidCheck').checked = true;
      setPhoneOrEmail(accountToKeep);
    }
  }, []);

  const [password, setPassword] = useState();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // const [passwordShown, setPasswordShown] = useState(false);
  // const eye = <FontAwesomeIcon icon={faEye} />;
  // const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

  // const togglePassword = () => {
  //   // When the handler is invoked
  //   // inverse the boolean state of passwordShown
  //   setPasswordShown(!passwordShown);
  // };

  const keepUserAccess = () => {
    if (document.getElementById('invalidCheck').checked === true) {
      document.cookie = `accountToKeep=${phoneOrEmail};max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
    } else {
      document.cookie = 'accountToKeep=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=ig.com.br';
    }
  };

  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const woocomerce = Cookies.get('woocomerce');

  const accountCookie = Cookies.get('user');

  const cpfCookie = Cookies.get('cpf');
  const birthdateCookie = Cookies.get('birthdate');
  const fullNameCookie = Cookies.get('fullName');
  const phoneNumberCookie = Cookies.get('phone_number');
  const zipCodeCookie = Cookies.get('zipCode');
  const streetCookie = Cookies.get('street');
  const districtCookie = Cookies.get('district');
  const cityCookie = Cookies.get('city');
  const stateCookie = Cookies.get('state');
  const numberCookie = Cookies.get('number');
  const complementCookie = Cookies.get('complement');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    try {
      Hub.listen('auth', async (data) => {
        const { email } = data.payload.data.attributes;
        const { name } = data.payload.data.attributes;
        const { birthdate } = data.payload.data.attributes;
        // eslint-disable-next-line camelcase
        const { phone_number } = data.payload.data.attributes;
        const cpf = data.payload.data.attributes['custom:cpf'];
        const zipCode = data.payload.data.attributes['custom:zipcode'];
        const street = data.payload.data.attributes['custom:street'];
        const city = data.payload.data.attributes['custom:city'];
        const state = data.payload.data.attributes['custom:state'];
        const district = data.payload.data.attributes['custom:district'];
        const number = data.payload.data.attributes['custom:number'];
        const complement = data.payload.data.attributes['custom:complement'];

        if (accountCookie === undefined) {
          document.cookie = `user=${email};max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (fullNameCookie === undefined) {
          document.cookie = `fullName=${name}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (birthdateCookie === undefined) {
          document.cookie = `birthdate=${birthdate}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (cpfCookie === undefined) {
          document.cookie = `cpf=${cpf}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (phoneNumberCookie === undefined) {
          // eslint-disable-next-line camelcase
          document.cookie = `phone_number=${phone_number}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (zipCodeCookie === undefined) {
          document.cookie = `zipCode=${zipCode}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (streetCookie === undefined) {
          document.cookie = `street=${street}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (cityCookie === undefined) {
          document.cookie = `city=${city}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (stateCookie === undefined) {
          document.cookie = `state=${state}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (districtCookie === undefined) {
          document.cookie = `district=${district}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (numberCookie === undefined) {
          document.cookie = `number=${number}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
        if (complementCookie === undefined) {
          document.cookie = `complement=${complement}; max-age=${60 * 60 * 24 * 180}; path=/; domain=ig.com.br`;
        }
      });
      keepUserAccess();
      await Auth.signIn(phoneOrEmail, password);
      if (woocomerce === undefined) {
        navigate('/homepage');
      } else {
        window.location.href = 'https://pet.ig.com.br/finalizar-compra-v2/';
      }
    } catch (e) {
      console.log(e.message);
      setHasError(true);
      if (e.message === 'User does not exist.') {
        setErrorMessage('Usuario inexistente');
      }
      if (e.message === 'Incorrect username or password.') {
        setErrorMessage('Senha ou email incorretos');
      }
      if (e.message === 'Custom auth lambda trigger is not configured for the user pool.') {
        setErrorMessage('Informe a senha');
      }
      if (e.message === 'Username cannot be empty') {
        setErrorMessage('Informe o Email');
      }
      if (e.message === 'User pool client 5v9anc7ekduakd6bd2c1tn74vn does not exist.') {
        setErrorMessage('Usuario inexistente. Realizar cadastro');
      }
    }
    setValidated(true);
  };

  return (
    <main>
      <div className="sidenav">
        <div className="login-main-text">
          <img src={Logo2} alt="" className="logo" />
        </div>
      </div>
      <div className="main-login">
        <div className="login-form-title">
          <p
            className="title-redefine"
            style={{
              textAlign: 'center',
            }}
          >
            Uma única conta para você acessar ou assinar os produtos e serviços iG

          </p>
        </div>
        <div className="col-md-6 col-sm-12 login-user">
          <div className="login-form">
            <h1 className="text-h1">INSIRA SEUS DADOS PARA ENTRAR</h1>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="form-login">
              <div>
                <Form.Control
                  id="login"
                  name={phoneOrEmail}
                  onFocus={() => setHasError(false)}
                  onChange={(event) => setPhoneOrEmail(event.target.value)}
                  type="text"
                  value={phoneOrEmail}
                  className="form-control"
                  placeholder="E-mail"
                  required
                />
                <Form.Control.Feedback className="invalid-feedback" type="invalid">
                  <span className="inv-feed">Campo obrigatório!</span>
                </Form.Control.Feedback>
              </div>
              <br />
              <div>
                <div id="second-form">
                  {/* <i className="eye-icon2" onClick={togglePassword}>{passwordShown ? eyeSlash : eye}</i> */}
                  <Form.Control
                    name={password}
                    onChange={(event) => setPassword(event.target.value)}
                    className="form-control"
                    id="pswrd"
                    placeholder="Senha"
                    // type={passwordShown ? 'text' : 'password'}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    <span className="inv-feed">Campo obrigatório!</span>
                  </Form.Control.Feedback>
                </div>
              </div>
              <div>
                { hasError && (
                <span className="msg-error-login">
                  {errorMessage}
                </span>
                )}
                <br />
                <div className="pull-right">
                  <input
                    className="input-pass"
                    type="checkbox"
                    value=""
                    id="invalidCheck"
                    // onChange={keepUserAccess}
                    required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    <p className="acesso">Guardar acesso</p>
                  </label>
                  <Link to="/recuperasenha">
                    <p className="forgot-password">Esqueceu a senha?</p>
                  </Link>
                </div>
                <div>
                  <button onClick={handleSubmit} type="button" className="btn-login"><b>ENTRAR</b></button>
                </div>
                <div>
                  <p className="text-create">
                    Novo (a) no IG?&nbsp;
                    <a href="/cadastro">Criar nova conta</a>
                  </p>
                </div>
                <div className="grey-line" />
                <div className="terms">
                  <p className="text-center">
                    Ao continuar com o acesso, você concorda
                    <br />
                    com a nossa&nbsp;
                    <a href="https://institucional.ig.com.br/2020-11-05/politica-de-privacidade-ig.html" target="_blank" rel="noreferrer">Política de Privacidade</a>
                  </p>
                </div>
              </div>
              <br />
              <br />
            </Form>
          </div>
        </div>
      </div>
      <br />
    </main>
  );
}

export default Login;
