import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import Logo from '../images/iglogo.png';
import 'bootstrap/dist/css/bootstrap.min.css';

import './StyleHomePage.css';

function HomePage() {
  useEffect(() => {
    document.title = 'iG - Últimas notícias, fotos, vídeos, esportes, entretenimento e mais.';
  }, []);

  const navigate = useNavigate();
  const signOut = async (event) => {
    event.preventDefault();
    try {
      await Auth.signOut();
      navigate('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };
  return (
    <div className="home-page">
      <div className="header-verif">
        <img className="logo-ig" src={Logo} alt="" />
      </div>
      <div className="code-page">
        <h1 className="h1-home">Seja Bem-vindo ao portal IG!</h1>
        <button type="button" className="btn  btn-primary" onClick={signOut}>SAIR</button>
      </div>
    </div>
  );
}

export default HomePage;
