import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './StylePageVerifCode.css';
import Cookies from 'js-cookie';
import Logo from '../images/iglogo.png';
// import 'bootstrap/dist/css/bootstrap.min.css';

function PageRecovery() {
  useEffect(() => {
    document.title = 'Sucesso!';
  }, []);

  const woocomerce = Cookies.get('woocomerce');
  const account = Cookies.get('user');

  useEffect(() => {
    if (woocomerce === 'true' && account !== undefined) window.location.href = 'https://pet.ig.com.br/finalizar-compra-v2/';
  }, []);

  const forgotPass = Cookies.get('forgotPass');

  return (
    <div className="code">
      <div className="header-verif">
        <img className="logo-ig" src={Logo} alt="" />
      </div>
      <div className="code-page">
        <h1 className="h1-confirm">SUCESSO!</h1>
        {woocomerce === 'true' && (
        <p className="p-confirm">Seu cadastro foi concluído, você vai ser redirecionado!</p>
        )}
        {forgotPass === undefined && woocomerce !== 'true' && (
        <p className="p-confirm">Seu cadastro foi concluído, agora você pode entrar na sua conta!</p>
        )}
        {forgotPass !== undefined && (
        <p className="p-confirm">Sua alteração de senha foi concluída, agora você pode entrar na sua conta!</p>
        )}
        {woocomerce !== 'true' && (
        <Link to="/">
          <button type="button" className="btn btn-primary btn-sm">ENTRAR</button>
        </Link>
        )}
      </div>
    </div>
  );
}

export default PageRecovery;
